import { IconButton, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink as Link } from "react-router-dom";
import Switch from "@mui/material/Switch";

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: "0px 10px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "Helvetica",
  // fontWeight: 600,

  [theme.breakpoints.up("sm")]: {
    padding: "0px 30px",
  },
}));

export const NavLink = styled(Link)(({ theme }) => ({
  color: "#606060",
  cursor: "pointer",
  padding: "10px 15px",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "#fafafa",
    padding: "5px 10px 1px",
    borderBottom: "5px solid #ffdf07",
  },
  // '&.active': {
  //   padding: '5px 10px 1px',
  //   borderBottom: '5px solid #ffdf07',
  // }
}));

export const Nav = styled(Grid)(({ theme }) => ({
  color: "#606060",
  cursor: "pointer",
  padding: "10px 15px",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "#fafafa",
    padding: "10px 15px 6px",
    borderBottom: "5px solid #ffdf07",
  },
  // '&.active': {
  //   padding: '5px 10px 1px',
  //   borderBottom: '5px solid #ffdf07',
  // }
}));

export const NavMenu = styled(Grid)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  justifyContent: "space-around",

  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

export const BurgerMenu = styled(IconButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));
export const ButtonLanguage = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('https://img.icons8.com/color/48/000000/great-britain.png')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#ff0a0a" : "#ff0a0a",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('https://img.icons8.com/color/48/000000/indonesia-circular.png')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
