import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Grid,
  Link,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsappIcon from "../../assets/whatsapp.png";
import {
  NavMenu,
  BurgerMenu,
  Nav,
  ContainerGrid,
  ButtonLanguage,
} from "./styleComponent";

import Logo from "../../assets/logo.png";
import contactUsImg from "../../assets/sub-menu/contact-us.jpg";
import ourLocationImg from "../../assets/sub-menu/our-locations.jpg";
import serviceImg from "../../assets/sub-menu/services.jpg";
import newsImg from "../../assets/sub-menu/c5_News.jpg";
import navb1 from "../../assets/sub-menu/project/b1_Civil_Engineering.webp";
import navb2 from "../../assets/sub-menu/project/b2_Multi_Story.webp";
import navb3 from "../../assets/sub-menu/project/b3_Industrial.webp";
import navc1 from "../../assets/sub-menu/project/c1_BPW.webp";
import navc2 from "../../assets/sub-menu/project/c2_Visi_Misi_Tata_Nilai.webp";
import navc3 from "../../assets/sub-menu/project/c3_History.webp";
import navc4 from "../../assets/sub-menu/project/c4_BPW HO pola logo.webp";

// import { useTranslation } from "react-i18next";
import {
  fetchDataCategoryProduct,
  fetchDataCategoryProject,
  fetchDataService,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
  changeLanguage,
} from "../../store/action";
import { BaseURL } from "../../config/API";
// import { useEffect } from "react";
function Navbar({
  fetchDataCategoryProduct,
  fetchDataService,
  dataCategoryProduct,
  fetchDataCategoryProject,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataEvent,
  dataCategoryProject,
  dataAboutUs,
  fetchBanner,
  fetchMetaTag,
  dataLanguage,
  changeLanguage,
}) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [menuSelected, setMenuSelected] = React.useState(null);
  const [menuDrawerSelected, setMenuDrawerSelected] = React.useState(null);
  const [translate, setTranslate] = React.useState(false);
  const [colorChange, setColorchange] = React.useState(false);
  React.useEffect(() => {
    async function fetch() {
      await fetchMetaTag();
      fetchBanner();
      fetchDataAboutUs();
      fetchDataCategoryProduct();
      fetchDataService();
      fetchDataCategoryProject();
      fetchDataNews();
      fetchDataEvent();
    }
    fetch();
    // eslint-disable-next-line
  }, []);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const handleMenuSelected = (event, args) => {
    if (args === menuSelected) {
      setMenuSelected(null);
    } else {
      setMenuSelected(args);
    }
  };

  const handleMenuDrawerSelected = (event, args) => {
    if (args === menuDrawerSelected) {
      setMenuDrawerSelected(null);
    } else {
      setMenuDrawerSelected(args);
    }
  };

  const navigate = (args, data) => () => {
    setMenuSelected(null);
    setMenuDrawerSelected(null);
    setOpenDrawer(false);

    if (data) history.push(args, { id: data });
    else history.push(args);
  };
  const handleTranslate = (e) => {
    setTranslate(!translate);
    console.log("dataLanguage", dataLanguage);
    // console.log(changeLanguage(true));
    // changeLanguage(!translate);
    changeLanguage(translate);
    console.log("translate", translate);
  };

  return (
    <Box sx={{ flexGrow: 1, fontFamily: "Helvetica !important" }}>
      <AppBar
        position="fixed"
        style={{
          maxWidth: "1920px",
          left: 0,
          margin: "0px auto",
          textTransform: "uppercase",
          fontFamily: "sans-serif",
          // backgroundColor: "black",
        }}
      >
        <Toolbar
          style={{
            height: colorChange ? 65 : 100,
            width: "100%",
            maxWidth: "1920px",
            backgroundColor: "#ffffff",
            padding: 0,
            // backgroundColor: colorChange ? "blue" : "red",
          }}
        >
          <ContainerGrid>
            <img
              src={Logo}
              style={{ width: "120px", cursor: "pointer" }}
              alt="logo-BPW"
              onClick={navigate("/")}
            />

            <NavMenu>
              <Nav
                onClick={(event) => handleMenuSelected(event, "product")}
                style={{
                  borderBottom: menuSelected === "product" && "5px solid red",
                  padding: menuSelected === "product" && "10px 15px 6px",
                }}
              >
                PRODUCT & SERVICES
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "project")}
                style={{
                  borderBottom: menuSelected === "project" && "5px solid red",
                  padding: menuSelected === "project" && "10px 15px 6px",
                }}
              >
                Project
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "about")}
                style={{
                  borderBottom: menuSelected === "about" && "5px solid red",
                  padding: menuSelected === "about" && "10px 15px 6px",
                }}
              >
                About Us
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "contact")}
                style={{
                  borderBottom: menuSelected === "contact" && "5px solid red",
                  padding: menuSelected === "contact" && "10px 15px 6px",
                }}
              >
                Contact
              </Nav>
              <Nav>
                {/* <ButtonLanguage
                  onClick={handleTranslate}
                  // checked={translate}
                  // defaultChecked={setTranslate(true)}
                /> */}
                <a
                  hrefLang="en-GB"
                  lang="en-GB"
                  style={{ marginRight: 20 }}
                  onClick={() => changeLanguage(false)}
                >
                  <span className="menu-text">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAt1BMVEWSmb66z+18msdig8La3u+tYX9IaLc7W7BagbmcUW+kqMr/q6n+//+hsNv/lIr/jIGMnNLJyOP9/fyQttT/wb3/////aWn+YWF5kNT0oqz0i4ueqtIZNJjhvt/8gn//WVr/6+rN1+o9RKZwgcMPJpX/VFT9UEn+RUX8Ozv2Ly+FGzdYZrfU1e/8LS/lQkG/mbVUX60AE231hHtcdMb0mp3qYFTFwNu3w9prcqSURGNDaaIUMX5FNW5wYt7AAAAAjklEQVR4AR3HNUJEMQCGwf+L8RR36ajR+1+CEuvRdd8kK9MNAiRQNgJmVDAt1yM6kSzYVJUsPNssAk5N7ZFKjVNFAY4co6TAOI+kyQm+LFUEBEKKzuWUNB7rSH/rSnvOulOGk+QlXTBqMIrfYX4tSe2nP3iRa/KNK7uTmWJ5a9+erZ3d+18od4ytiZdvZyuKWy8o3UpTVAAAAABJRU5ErkJggg=="
                      alt="EN"
                      width="16"
                      height="11"
                      style={{ width: 16 + "px", height: 11 + "px" }}
                    />
                    {dataLanguage ? (
                      <span
                        style={{
                          marginLeft: 0.3 + "em",
                        }}
                      >
                        EN
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: 0.3 + "em",
                          fontWeight: "bolder",
                          fontSize: 17,
                          color: "red",
                        }}
                      >
                        EN
                      </span>
                    )}
                  </span>
                </a>
                <a
                  hrefLang="id-ID"
                  lang="id-ID"
                  onClick={() => changeLanguage(true)}
                >
                  <span className="menu-text">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAARVBMVEXfAgDPAADskYrvh4Dsd27qa2PoYVi9AADmV03kTULiQzfeMyf2vrryq6ftoJvMGA/8/Pz5+fn39/fz8/Pm5ubg4ODt7u3lScs2AAAAR0lEQVR4AQXBsRGDQBAAMe39e0gcuP86CTEgBSAgIwipzYiMdBmJjITNMJJYd58jhBPf+kEiaCARaFIiWEyA58Gf3bJwI3gBwHkHZeHbupcAAAAASUVORK5CYII="
                      alt="ID"
                      width="16"
                      height="11"
                      style={{ width: 16 + "px", height: 11 + "px" }}
                    />
                    {dataLanguage ? (
                      <span
                        style={{
                          marginLeft: 0.3 + "em",
                          fontWeight: "bolder",
                          fontSize: 17,
                          color: "red",
                        }}
                      >
                        ID
                      </span>
                    ) : (
                      <span style={{ marginLeft: 0.3 + "em" }}>ID</span>
                    )}
                  </span>
                </a>
              </Nav>
            </NavMenu>

            <BurgerMenu>
              <MenuIcon onClick={() => setOpenDrawer(!openDrawer)} />
            </BurgerMenu>
          </ContainerGrid>
        </Toolbar>

        {menuSelected && (
          <Box
            style={{
              backgroundColor: "#ebeced",
              padding: "20px 10px",
              display: "flex",
              overflowX: "auto",
              alignItems: "center",
            }}
            onClick={() => setMenuSelected(null)}
          >
            {menuSelected === "product" ? (
              <>
                {dataCategoryProduct.map((category, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/product", category.id)}
                    key={`category-product-${index}`}
                  >
                    <img
                      src={`${BaseURL}/category-product/${category.thumbnail}`}
                      alt={`category-${index}`}
                      style={{ width: 177, height: 110, marginBottom: 5 }}
                    />
                    <b style={{ color: "#606060" }}>{category.name}</b>
                  </Grid>
                ))}
                <Grid
                  style={{
                    width: 177,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "1%",
                    marginRight: "2%",
                    cursor: "pointer",
                  }}
                  onClick={navigate("/service")}
                >
                  <img
                    src={serviceImg}
                    alt="service"
                    style={{ width: 177, height: 110, marginBottom: 5 }}
                  />
                  <b style={{ color: "#606060" }}>Services</b>
                </Grid>
              </>
            ) : menuSelected === "project" ? (
              <>
                {dataCategoryProject.map((category, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/project", category.id)}
                    key={`project-${index}`}
                  >
                    <img
                      src={
                        category.id === 6
                          ? navb1
                          : category.id === 5
                          ? navb2
                          : navb3
                      }
                      // src={`${BaseURL}/category-product/${category.thumbnail}`}
                      alt={`category-${index}`}
                      style={{ width: 177, height: 110, marginBottom: 5 }}
                    />
                    <b style={{ color: "#606060" }}>{category.name}</b>
                  </Grid>
                ))}
              </>
            ) : menuSelected === "about" ? (
              <>
                {dataAboutUs.map((el, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/about-us", el.id)}
                    key={`about-${index}`}
                  >
                    {console.log("ABOUUT", el.id)}
                    <img
                      src={
                        el.id === 16
                          ? navc1
                          : el.id === 15
                          ? navc2
                          : el.id === 14
                          ? navc3
                          : navc4
                      }
                      alt={`el-${index}`}
                      style={{ width: 177, height: 110, marginBottom: 5 }}
                    />
                    <b style={{ color: "#606060", textAlign: "center" }}>
                      {el.title}
                    </b>
                  </Grid>
                ))}
                <Grid
                  style={{
                    width: 177,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "1%",
                    marginRight: "2%",
                    cursor: "pointer",
                  }}
                  onClick={navigate("/about-us", "news")}
                >
                  <img
                    src={newsImg}
                    alt="contact-us"
                    style={{ width: 177, height: 110, marginBottom: 5 }}
                  />
                  <b style={{ color: "#606060", textAlign: "center" }}>
                    News & Event
                  </b>
                </Grid>
              </>
            ) : (
              menuSelected === "contact" && (
                <>
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/contact-us", { status: "contact" })}
                  >
                    <img
                      src={contactUsImg}
                      alt="contact-us"
                      style={{ width: 177, height: 110, marginBottom: 5 }}
                    />
                    <b style={{ color: "#606060" }}>Contact Us</b>
                  </Grid>

                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/our-location", { status: "location" })}
                  >
                    <img
                      src={ourLocationImg}
                      alt="our-location"
                      style={{ width: 177, height: 110, marginBottom: 5 }}
                    />
                    <b style={{ color: "#606060" }}>Our Locations</b>
                  </Grid>
                </>
              )
            )}
          </Box>
        )}

        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box
            sx={{ width: 250, textTransform: "uppercase" }}
            role="presentation"
            // onClick={() => setOpenDrawer(false)}
            // onKeyDown={() => setOpenDrawer(false)}
          >
            <List>
              <ListItem
                button
                key="Product & Services"
                onClick={(event) => handleMenuDrawerSelected(event, "product")}
              >
                <ListItemText primary="Product & Services" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "product"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataCategoryProduct.map((category, index) => (
                    <ListItem
                      button
                      key={"category" + index}
                      component={Link}
                      onClick={navigate("/product", category.id)}
                    >
                      <ListItemText
                        primary={category.name}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    key="Services"
                    component={Link}
                    onClick={navigate("/service")}
                    // to={submenu.link}
                  >
                    <ListItemText
                      primary="Services"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="Project"
                onClick={(event) => handleMenuDrawerSelected(event, "project")}
              >
                <ListItemText primary="Project" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "project"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataCategoryProject.map((category, index) => (
                    <ListItem
                      button
                      key={"category" + index}
                      component={Link}
                      onClick={navigate("/project", category.id)}
                    >
                      <ListItemText
                        primary={category.name}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <ListItem
                button
                key="About Us"
                onClick={(event) => handleMenuDrawerSelected(event, "about")}
              >
                <ListItemText primary="About Us" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "about"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataAboutUs.map((about, index) => (
                    <ListItem
                      button
                      key={"about" + index}
                      component={Link}
                      onClick={navigate("/about-us", about.id)}
                    >
                      <ListItemText
                        primary={about.title}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    component={Link}
                    onClick={navigate("/about-us", "news")}
                  >
                    <ListItemText
                      primary="News & Event"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="Contact"
                onClick={(event) => handleMenuDrawerSelected(event, "contact")}
              >
                <ListItemText primary="Contact" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "contact"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    key="Services"
                    onClick={navigate("/contact-us")}
                  >
                    <ListItemText
                      primary="Contact Us"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    key="Services"
                    onClick={navigate("/our-location")}
                  >
                    <ListItemText
                      primary="Our Locations"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem button key="Language">
                <ButtonLanguage
                  onClick={handleTranslate}
                  // checked={translate}
                  // defaultChecked={setTranslate(true)}
                />
              </ListItem>
              {/* 
              <Grid style={{ width: 177, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1%', marginRight: '2%', cursor: 'pointer' }} >
                <img src={contactUsImg} alt="contact-us" style={{ width: 177, height: 110, marginBottom: 5 }} />
                <b style={{ color: '#606060' }}>Contact Us</b>
              </Grid>

              <Grid style={{ width: 177, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1%', marginRight: '2%', cursor: 'pointer' }} onClick={navigate('/our-location')}>
                <img src={ourLocationImg} alt="our-location" style={{ width: 177, height: 110, marginBottom: 5 }} />
                <b style={{ color: '#606060' }}>Our Locations</b>
              </Grid> */}
            </List>
          </Box>
        </Drawer>
      </AppBar>
      <div style={{ position: "fixed", right: 10, bottom: 10, zIndex: 99999 }}>
        <img
          src={WhatsappIcon}
          alt="contact-us"
          style={{
            width: 50,
            height: 50,
            marginLeft: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+6282113393717&text=Halo, saya ingin menanyakan tentang `
            )
          }
        />
      </div>
    </Box>
  );
}

const mapStateToProps = ({
  dataCategoryProduct,
  dataCategoryProject,
  dataAboutUs,
  dataLanguage,
}) => {
  return {
    dataCategoryProduct,
    dataCategoryProject,
    dataAboutUs,
    dataLanguage,
  };
};

const mapDispatchToProps = {
  fetchDataCategoryProduct,
  fetchDataService,
  fetchDataCategoryProject,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
