import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import Helmet from "./components/helmet";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { makeStyles } from "@mui/styles";

import Routes from "./navigation/routes";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    maxWidth: "1920px",
    margin: "0px auto",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className={classes.root}>
          <Helmet
            title=""
            description="Web site PT Beton Perkasa Wijaksana"
            keyword="PT Beton Perkasa Wijaksana, Polagroup"
          />
          <Navbar />
          <Routes />
          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
