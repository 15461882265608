import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";
import styled from "styled-components";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
const FollowUsContainer = styled.div`
  background-color: #ffdd00;
  display: flex;
  justify-content: center;
`;

const FollowUsContent = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  height: auto; /* Set default height */

  @media (max-width: 767px) {
    height: 60px; /* Set height for mobile */
  }
  b {
    margin-right: 10px;
    font-family: Helvetica;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }
`;

function Index({ dataCategoryProduct, dataService, dataAboutUs }) {
  const history = useHistory();
  return (
    <>
      {/* MEDSOS */}
      {/* <Grid
        style={{
          backgroundColor: "#ffdd00",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "10px",
            display: "flex",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          <b
            style={{
              marginRight: 10,
              fontFamily: "Helvetica",
              fontSize: 16,
            }}
          >
            Follow Us :
          </b>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <a
              href="https://www.facebook.com/betonperkasawijaksanaofficial/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                className="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              <span> @betonperkasawijaksanaofficial </span>
              <span> | </span>
            </a>

            <a
              href="https://www.instagram.com/betonperkasawijaksana/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                className="bi bi-instagram"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
              <span> betonperkasawijaksana </span> <span> | </span>
            </a>

            <a
              href="https://www.betonperkasa.com/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                className="bi bi-globe-americas"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
              </svg>
              <span> www.betonperkasa.com </span> <span> | </span>
            </a>
            <a
              href="https://bit.ly/2DkPaBM"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                className="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
              <span> Beton Perkasa Wijaksana </span> <span> | </span>
            </a>

            <a
              href="https://www.linkedin.com/company/pt-beton-perkasa-wijaksana/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                className="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
              </svg>
              <span> Beton perkasa wijaksana ofc</span>
            </a>
          </div>
        </Grid>
      </Grid> */}
      <FollowUsContainer>
        <FollowUsContent>
          <b>Follow Us:</b>
          <a
            href="https://www.facebook.com/betonperkasawijaksanaofficial/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              className="bi bi-facebook"
              viewBox="0 0 16 16"
              style={{ marginRight: 1 }}
            >
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
            </svg>
            <span>@betonperkasawijaksanaofficial</span>
          </a>
          <a
            href="https://www.instagram.com/betonperkasawijaksana/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              className="bi bi-instagram"
              viewBox="0 0 16 16"
              style={{ marginRight: 1 }}
            >
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
            <span>betonperkasawijaksana</span>
          </a>
          <a
            href="https://www.betonperkasa.com/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              className="bi bi-globe-americas"
              viewBox="0 0 16 16"
              style={{ marginRight: 1 }}
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
            </svg>
            <span> www.betonperkasa.com</span>
          </a>
          <a href="https://bit.ly/2DkPaBM" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              className="bi bi-whatsapp"
              viewBox="0 0 16 16"
              style={{ marginRight: 1 }}
            >
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
            <span> Beton Perkasa Wijaksana</span>
          </a>
          <a
            href="https://www.linkedin.com/company/pt-beton-perkasa-wijaksana/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              className="bi bi-linkedin"
              viewBox="0 0 16 16"
              style={{ marginRight: 1 }}
            >
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
            <span> Beton perkasa wijaksana ofc</span>
          </a>

          {/* Add other social media links similarly */}
        </FollowUsContent>
      </FollowUsContainer>

      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "20px 0px",
            display: "flex",
          }}
        >
          <FirstFooter item xs={12} md={4}>
            <h3 style={{ margin: 0, fontFamily: "Helvetica" }}>
              PT Beton Perkasa Wijaksana
            </h3>
            <h4
              style={{
                margin: 0,
                marginBottom: 20,
                fontFamily: "Helvetica",
              }}
            >
              Total Solution Formwork & Scaffolding
            </h4>
            <b style={{ fontSize: 15 }}>Head Office</b>
            <p style={{ fontSize: 14 }}>Jl. Raya Serang Km. 16,7</p>
            <p style={{ fontSize: 14 }}>Cikupa, Tangerang 15710 - Indonesia</p>
            <p style={{ fontSize: 14 }}>Phone : +62(21) 596 0765</p>
            <p style={{ fontSize: 14 }}>Fax : +62(21) 596 0767</p>
            <p style={{ fontSize: 14 }}>Mail : bpw@betonperkasa.com</p>
            <p style={{ fontSize: 14 }}>Website : www.betonperkasa.com</p>
          </FirstFooter>

          <SecondFooter item md={8}>
            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Products Types</b>
              {dataCategoryProduct.map((categoryProduct, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/product", { id: categoryProduct.id })
                  }
                  key={`categoryProduct-${index}`}
                >
                  {categoryProduct.name}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Our Services</b>
              {/* <p style={{ fontSize: 14 }}>Overview</p> */}
              {dataService.map((service, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/service", { id: service.id })}
                  key={`service-${index}`}
                >
                  {service.title}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Our Company</b>
              {dataAboutUs.map((aboutus, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/about-us", { id: aboutus.id })}
                  key={`about-us-${index}`}
                >
                  {aboutus.title}
                </p>
              ))}
              <p
                style={{ fontSize: 14, cursor: "pointer" }}
                onClick={() => history.push("/sitemap")}
              >
                Sitemap
              </p>
            </Grid>
          </SecondFooter>
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 10 }}>
          © Copyright 2021 PT Beton Perkasa Wijaksana, All Right Reserved
        </p>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataService, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataService,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
